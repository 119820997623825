import React from 'react';
import Img from 'gatsby-image';

/**
 * Components
 */

import { Heart } from '@components/icon/icon';

const PropertyBox = ({image, title, price, beds, children, propimage,link}) => {
    return (
        <div className="property-box">
            <a href={`${link}/`}>
            <div className="property-box__image">
                {propimage == null &&
                    <Img fluid={image} />
                }
                {propimage != null &&
                    <img src={propimage} />
                }                
            </div>
            </a>
            <div className="property-box__content">
                <h4>{title}</h4>
                <div className="price">
                    <span className="d-md-none">Guide Price</span> {price}
                </div>
                <small className="beds">
                    {beds}
                </small>
                {children}
            </div>
        </div>
    )
}

export default PropertyBox
